
import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';

const FileUploader3 = ({ onChange }) => {
  const [files, setFiles] = useState([]);

  const onDrop = (acceptedFiles) => {
    setFiles([...files, ...acceptedFiles]);
    onChange([...files, ...acceptedFiles]); // ارسال تعداد فایل‌ها به تابع ارسال شده به عنوان پارامتر
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div {...getRootProps()} style={dropzoneStyles}>
      <input {...getInputProps()} />
      {isDragActive ? (
        <p>فایل‌ها را اینجا رها کنید!</p>
      ) : (
        <p>فایل‌ها را اینجا بکشید یا کلیک کنید تا انتخاب شوند.</p>
      )}
    </div>
  );
};

const dropzoneStyles = {
  border: '2px dashed #cccccc',
  borderRadius: '4px',
  padding: '20px',
  textAlign: 'center',
  cursor: 'pointer',
};

export default FileUploader3;
