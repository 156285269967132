import React, { useState, useEffect } from "react";
import {
  Modal,
  Paper,
  TextField,
  Button,
} from "@mui/material";
import axios from "axios";
import { Email } from "@mui/icons-material";
import config from "../../config";
import FileUploader2 from "../../Components/files/FileUploader2";
import PersianDatePicker from "../../Components/PersianDatePicker";
import jalaliMoment from "jalali-moment";

function ModalExcer({ isOpen, onClose, Idd }) {

  const [selectedMiladiDate, setSelectedMiladiDate] = useState(null);

  // Step 2: Pass a callback function to YourComponent
  const handleDateChange = (miladiDate) => {
    setSelectedMiladiDate(miladiDate);
  };

  //
  const [selectedGregorianDate, setSelectedGregorianDate] = useState(null);

  // Callback function to update the selectedGregorianDate
  const handleGregorianDateChange = (gregorianDate) => {
    setSelectedGregorianDate(gregorianDate);

    // مقدار تاریخ میلادی را در اینجا می‌توانید چاپ کنید
    console.log("Gregorian Date in App.js:", gregorianDate);
  };
  //

  const [exercisesData, setExercisesData] = useState({
    name: "",
    subject1: "",
    subject2: "",
    preparation: "",
    execution: "0",
  });

  const convertGregorianToShamsi = (gregorianDate) => {
    // Use jalali-moment to convert Gregorian to Shamsi
    const shamsiDate = jalaliMoment(gregorianDate).format("jYYYY-jMM-jDD");
    return shamsiDate;
  };
  useEffect(() => {
    if (Idd > 0) {

      let jwt_token = "";
      const localStorageData = localStorage.getItem("user");
      if (localStorageData) {
        const parsedData = JSON.parse(localStorageData);
        jwt_token = parsedData.token;
      } else {
        jwt_token = "no";
      }

      const authToken = jwt_token;
      const headers = {
        Authorization: `Bearer ${authToken}`,
      };
      axios
        .get(config.hostname + `/api/v1/Exercise/${Idd}`, { headers })
        .then((response) => {
          const exercisesDataaa = response.data;
          setExercisesData({
            name: exercisesDataaa.name,
            subject1: exercisesDataaa.subject1,
            subject2: exercisesDataaa.subject2,
            preparation: exercisesDataaa.preparation,
            execution: exercisesDataaa.execution,
          });
        })
        .catch((error) => {
          console.error("خطا در درخواست به API: ", error);
        });

    } else {
      setExercisesData({
        name: "",
        subject1: "",
        subject2: "",
        preparation: "",
        execution: "",
      });
    }
  }, [Idd]);

  const updateUser = async () => {
    try {
      let jwt_token = "";
      const localStorageData = localStorage.getItem("user");
      if (localStorageData) {
        const parsedData = JSON.parse(localStorageData);
        jwt_token = parsedData.token;
      } else {
        jwt_token = "no";
      }

      const authToken = jwt_token;
      console.log("eeddd");
      const headers = {
        Authorization: `Bearer ${authToken}`,
      };

      const response = await axios.put(
        config.hostname + `/api/v1/Exercise`,
        {
          keyID: Idd,
          name: exercisesData.name,
          subject1: "نحوه اجرای تمرین",
          subject2: "نکات تمرین",
          preparation: exercisesData.preparation,
          execution: exercisesData.execution,
          otherDescription: exercisesData.execution,
          exercisesCategoryID: -1,
        },
        { headers }
      );

      console.log("ویرایش با موفقیت انجام شد", response.data);

      onClose();
    } catch (error) {
      console.error("خطا در ویرایش کاربر: ", error);
    }
  };

  const insertuser = async () => {
    try {
      let jwt_token = "";
      const localStorageData = localStorage.getItem("user");
      if (localStorageData) {
        const parsedData = JSON.parse(localStorageData);
        jwt_token = parsedData.token;
      } else {
        jwt_token = "no";
      }
      console.log(config.hostname);
      const authToken = jwt_token;
      const headers = { Authorization: `Bearer ${authToken}` };

      const response = await axios.post(
        config.hostname + `/api/v1/Exercise`,
        {
          keyID: -1,
          name: exercisesData.name,
          subject1: "نحوه اجرای تمرین",
          subject2: "نکات تمرین",
          preparation: exercisesData.preparation,
          execution: exercisesData.execution,
          otherDescription: exercisesData.execution,
          exercisesCategoryID: -1,
        },
        { headers }
      );

      console.log("ایجاد با موفقیت انجام شد", response.data);

      onClose();
    } catch (error) {
      console.error("خطا در ویرایش کاربر: ", error);

    }
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Paper
        sx={{
          position: "absolute",
          display: "flex",
          flexDirection: "row",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
          padding: 2,
        }}
      >
        <div style={{ flex: 1, padding: "0 10px" }}>
          <h2>{Idd > 0 ? "ویرایش تمرین" : "ایجاد تمرین"}</h2>
          <hr style={{ borderTop: "2px solid blue", margin: "10px 0" }} />

          <TextField
            itemID="exercisesName"
            label="عنوان تمرین"
            value={exercisesData.name}
            onChange={(e) =>
              setExercisesData({ ...exercisesData, name: e.target.value })
            }
            fullWidth
            style={{ marginTop: "10px" }}
            dir="rtl"
          />

          <TextField
            itemID="exercisesSubject1"
            label="عنوان نحوه اجرای تمرین"
            value={exercisesData.subject1}
            onChange={(e) =>
              setExercisesData({ ...exercisesData, subject1: e.target.value })
            }
            fullWidth
            style={{ marginTop: "10px", display: "none" }}
            dir="rtl"
          />

          <TextField
            itemID="exercisesPreparation"
            label="نحوه اجرای تمرین"
            value={exercisesData.preparation}
            onChange={(e) =>
              setExercisesData({
                ...exercisesData,
                preparation: e.target.value,
              })
            }
            multiline // اضافه کردن prop multiline
            rows={6} // تنظیم تعداد ردیف‌ها
            fullWidth
            style={{ marginTop: "10px" }}
            dir="rtl"
          />

          <TextField
            itemID="exercisesSubject2"
            label="عنوان نکات تمرین"
            value={exercisesData.subject2}
            onChange={(e) =>
              setExercisesData({ ...exercisesData, subject2: e.target.value })
            }
            fullWidth
            style={{ marginTop: "10px", display: "none" }}
            dir="rtl"
          />

          <TextField
            itemID="exercisesExecution"
            label="نکات تمرین"
            value={exercisesData.execution}
            onChange={(e) =>
              setExercisesData({ ...exercisesData, execution: e.target.value })
            }
            multiline // اضافه کردن prop multiline
            rows={6} // تنظیم تعداد ردیف‌ها
            fullWidth
            style={{ marginTop: "10px" }}
            dir="rtl"
          />

          <TextField
            itemID="exercisesCategoryID"
            label="شناسه دسته‌بندی آموزش"
            value={exercisesData.exercisesCategoryID}
            onChange={(e) =>
              setExercisesData({
                ...exercisesData,
                exercisesCategoryID: e.target.value,
              })
            }
            fullWidth
            style={{ marginTop: "10px", display: "none" }}
            dir="rtl"
          />

          <Button
            variant="contained"
            onClick={Idd > 0 ? updateUser : insertuser}
            sx={{ marginTop: 2 }}
          >
            {Idd > 0 ? "ویرایش تمرین" : "ایجاد تمرین"}
          </Button>
        </div>
      </Paper>
    </Modal>
  );
}

export default ModalExcer;
