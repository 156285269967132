import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { Button } from "@mui/material";
import ModalExcer from "./modal-Excer";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import config from "../../config.js";
import ModalAttachExeFile from "./modalAttachExeFile";
import ModalSetParameter from "./modalSetParameter";
import ModalGroup from "./modalGroup";
import Searchbox from "../../Components/Searchbox/Searchbox.jsx";

import "./ExercisesList.css";

function Exerciselist() {
  const [exerciseList, setexerciseList] = useState([]);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [editingexerciseId, setEditingexerciseId] = useState(null);
  const [isAttachModalOpen, setAttachModalOpen] = useState(false);
  const [AttachModalID, setAttachEduId] = useState(false);
  const [EDUIDForModalAttach, setEDUIDForModalAttach] = useState(false);
  const [EDUIDForModalGroup, setEDUIDForModalGroup] = useState(false);
  const [isParameterModalOpen, setParameterModalOpen] = useState(false);
  const [isGroupModalOpen, setGroupModalOpen] = useState(false);

  const handleEditClick = (exerciseId) => {
    setEditingexerciseId(exerciseId);

    setEditModalOpen(true);
  };

  const handleAttachFilesClick = (educationId) => {
    setAttachEduId(educationId);

    setAttachModalOpen(true);
  };
  const handleafterAttach = () => {
    setAttachModalOpen(false);
  };

  const handleParameterModalClick = (educationId) => {
    setEDUIDForModalAttach(educationId);

    setParameterModalOpen(true);
  };
  const handleafterParameterModal = () => {
    setParameterModalOpen(false);
  };

  const handleGroupClick = (educationId) => {
    setEDUIDForModalGroup(educationId);

    setGroupModalOpen(true);
  };
  const handleafterGroupModal = () => {
    setGroupModalOpen(false);
  };

  const handleafterEdit = () => {
    setEditModalOpen(false);
    refreshlist();
  };
  const refreshlist = () => {
    console.log("delete sucsess!");
    let jwt_token = "";
    const localStorageData = localStorage.getItem("user");
    if (localStorageData) {
      const parsedData = JSON.parse(localStorageData);
      jwt_token = parsedData.token;
    } else {
      jwt_token = "no";
    }
    const authToken = jwt_token;
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    axios
      .get(config.hostname + "/api/v1/Exercise/-1,1,1000", { headers })
      .then((response) => {
        const exerciseData = response.data["exerciseslist"];

        const formattedData = exerciseData.map((item) => ({
          id: item.keyID,
          name: item.name,
          preparation: item.preparation,
        }));
        setexerciseList(formattedData);
      })
      .catch((error) => {
        console.error("خطا در درخواست به API: ", error);
      });
  };

  useEffect(() => {
    let jwt_token = "";
    const localStorageData = localStorage.getItem("user");
    if (localStorageData) {
      const parsedData = JSON.parse(localStorageData);
      jwt_token = parsedData.token;
    } else {
      jwt_token = "no";
    }
    const authToken = jwt_token;
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    axios
      .get(config.hostname + "/api/v1/Exercise/-1,1,1000", { headers })
      .then((response) => {
        const exerciseData = response.data["exerciseslist"];

        const formattedData = exerciseData.map((item) => ({
          id: item.keyID,
          name: item.name,
          preparation: item.preparation,
        }));

        setexerciseList(formattedData);
      })
      .catch((error) => {
        console.error("خطا در درخواست به API: ", error);
      });
  }, []);

  const deleteexercise = (exerciseId) => {
    const confirmDelete = window.confirm(
      "آیا مطمئن هستید که می‌خواهید این تمرین را حذف کنید؟"
    );

    if (confirmDelete) {
      let jwt_token = "";
      const localStorageData = localStorage.getItem("user");
      if (localStorageData) {
        const parsedData = JSON.parse(localStorageData);
        jwt_token = parsedData.token;
      } else {
        jwt_token = "no";
      }
      const authToken = jwt_token;
      const headers = {
        Authorization: `Bearer ${authToken}`,
      };
      axios
        .delete(config.hostname + `/api/v1/exercise/${exerciseId}`, { headers })

        .catch((error) => {
          console.error("خطا در درخواست به API برای حذف تمرین: ", error);
        });
      refreshlist();
    }
  };

  const columns = [
    {
      field: "action",
      headerName: "عملیات",
      width: 150,
      renderHeader: () => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <span style={{ marginTop: "1px" }}>عملیات</span>
        </div>
      ),
      renderCell: (params) => {
        return (
          <>
            <Link
              className="link"
              onClick={() => handleEditClick(params.row.id)}
            >
              <EditIcon className="userListEdit" />
            </Link>

            <Link className="link">
              <img
                src="/images/parameters.png"
                alt="attach"
                style={{
                  width: "20px",
                  height: "20px",
                }}
                onClick={() => handleParameterModalClick(params.row.id)}
              />
            </Link>
            <Link className="link">
              <img
                src="/images/attachicon.png"
                alt="attach"
                style={{
                  width: "20px",
                  height: "20px",
                }}
                onClick={() => handleAttachFilesClick(params.row.id)}
              />
            </Link>

            <Link className="link">
              <DeleteIcon
                className="userListTrash"
                onClick={() => deleteexercise(params.row.id)}
              />
            </Link>

            <Link className="link">
              <img
                src="/images/hashtag.png"
                alt="attach"
                style={{
                  width: "20px",
                  height: "20px",
                }}
                onClick={() => handleGroupClick(params.row.id)}
              />
            </Link>
          </>
        );
      },
    },
    {
      field: "id",
      headerName: "شناسه",
      width: 90,
    },
    {
      field: "name",
      headerName: "نام",
      width: 250,
    },
    {
      field: "preparation",
      headerName: "مراحل اماده سازی",
      width: 400,
    },
  ];

  return (
    <>
      <div sx={{ height: 520, width: "90%" }} className="userList">
        <div className="education-actions">
          <Button onClick={() => handleEditClick(0)} variant="contained">
            افزودن
            <AddCircleOutlineIcon className="userListInsert" />
          </Button>
          <Searchbox />
        </div>
        <DataGrid
          rows={exerciseList}
          columns={columns}
          pageSize={10}
          disableSelectionOnClick
          checkboxSelection
        />
      </div>

      <ModalExcer
        isOpen={isEditModalOpen}
        onClose={() => handleafterEdit()}
        Idd={editingexerciseId}
      />
      <ModalAttachExeFile
        isOpen={isAttachModalOpen}
        onClose={() => handleafterAttach()}
        Idd={AttachModalID}
      />

      <ModalSetParameter
        isOpen={isParameterModalOpen}
        onClose={() => handleafterParameterModal()}
        Idd={EDUIDForModalAttach}
      />
      <ModalGroup
        isOpen={isGroupModalOpen}
        onClose={() => handleafterGroupModal()}
        Idd={EDUIDForModalGroup}
      />
    </>
  );
}

export default Exerciselist;
