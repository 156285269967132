import React from "react";

import './transactionBox.css'

export default function TransactionBox({ title, value }) {
  return (
    <div className="transaction-box">
      <h2>{title}</h2>
      <span>{value}</span>
    </div>
  );
}
