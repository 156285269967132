import React from "react";
import Button from "@mui/material/Button";
import "./Displayimages.css";

const DisplayImages = ({ filesData, onDeleteFile }) => {
  return (
    <div>
      <div id="imagesContainer">
        {filesData.map((fileData, index) => (
          <div key={index} className="imageContainer">
            <div className="imageWrapper">
              <a
                href={fileData.FilesAddress}
                rel="noreferrer"
                target="_blank"
                key={index}
              >
                {["jpg", "jpeg", "png", "gif", "webp"].includes(
                  fileData.FileType
                ) ? (
                  <img src={fileData.FilesAddress} alt={`file ${index}`} />
                ) : [
                    "mp4",
                    "avi",
                    "mkv",
                    "wmv",
                    "webm",
                    "mpeg",
                    "mpg",
                  ].includes(fileData.FileType) ? (
                  <embed
                    src={fileData.FilesAddress}
                    type="application/pdf"
                    width="140px"
                    height="80px"
                  />
                ) : fileData.FileType === "pdf" ? (
                  <img src="images/images.jfif" alt={`file ${index}`} />
                ) : fileData.FileType === "xlsx" ? (
                  <img src="images/excel logo.png" alt={`file ${index}`} />
                ) : (
                  <img src="images/file logo.png" alt={`file ${index}`} />
                )}
              </a>
              {onDeleteFile && (
                <div className="buttonWrapper">
                  <Button
                    onClick={() =>
                      onDeleteFile(fileData.KeyID, fileData.isImage)
                    }
                    variant="secondary"
                    color="outlined"
                    sx={{
                      fontSize: "12px",
                      width: "20px",
                      height: "20px",
                      marginTop: "1px",
                      "&:hover": {
                        backgroundColor: "red",
                      },
                    }}
                  >
                    X
                  </Button>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DisplayImages;
