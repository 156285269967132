import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import './Topbar.css'
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import LanguageIcon from '@mui/icons-material/Language';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/ExitToApp';
import AuthContext from '../../Contexts/authContext';

function Topbar() {
   
        const navigate = useNavigate();
        const authContext = useContext(AuthContext);
        const logout = () => {
            // اطلاعات مربوط به یوزر را از localStorage حذف می‌کنیم
            localStorage.removeItem('user');
            // سپس سایر مراحل لاگ‌اوت را انجام می‌دهیم
            // ...
          };
        const handleLogout = () => {
           localStorage.removeItem('user');
          authContext.logout(); // اگر یک متد logout در context شما وجود دارد

          navigate('/login');
        };



    return(
        <>
         <div className="topbar">
            <div className="topbarWrapper">

                <div className="topLeft">
                    <span className="logo">Admin</span>
                </div>

                <div className="topRight">
{/*
                    <div className="topbarIconContainer">
                        <NotificationsNoneIcon />
                        <span className="iconBadge">2</span>
                    </div>

                    <div className="topbarIconContainer">
                        <LanguageIcon />
                        <span className="iconBadge">2</span>
                    </div>
    */}
                    <div className="topbarIconContainer">
                     { /*  <SettingsIcon onClick={handleLogout} />    */}
                        <LogoutIcon onClick={handleLogout} />
                    </div>
{/*
                    <img src="images/profile.jpg" className="topAvator"/>*/}

                </div>

            </div>
         </div>
        </>
    )
}

export default Topbar