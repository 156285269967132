let xAxisData = [
    {
        "name" : 'Jan',
        "Sale" : 112_000
    },
    {
        "name" : 'feb',
        "Sale" : 99_000
    },
    {
        "name" : 'Mar',
        "Sale" : 112_000
    },
    {
        "name" : 'Mar',
        "Sale" : 54_000
    },
    {
        "name" : 'Apr',
        "Sale" : 99_000
    },
    {
        "name" : 'May',
        "Sale" : 52_000
    },
    {
        "name" : 'Jun',
        "Sale" : 85_000
    },
    {
        "name" : 'Jul',
        "Sale" : 34_000
    },
    {
        "name" : 'Agu',
        "Sale" : 17_000
    },
    {
        "name" : 'Sep',
        "Sale" : 0
    },
    {
        "name" : 'Oct',
        "Sale" : 73_000
    },
    {
        "name" : 'Nov',
        "Sale" : 12_900
    },
    {
        "name" : 'Dev',
        "Sale" : 97_000
    },
]

let users = [
    {id:1, name: 'Mahdiye Kaffash', job: 'web Developer', img: 'images/user.jpg'},
    {id:2, name: 'Qadir Yolme', job: 'Hacker', img: 'images/index.jpg'},
    {id:3, name: 'Hamid Mortazavi', job: 'React Developer', img: 'images/index2.jpg'},
    {id:4, name: 'Mohammad Amin', job: 'web Developer', img: 'images/index.jpg'},
    {id:5, name: 'Sajjad Heydari', job: 'Computer engenier', img: 'images/user.jpg'},
]


const transAction = [
    {id: 1, customer: 'Mahdiye Kaffash',date: '20 May 2022', Amount: '$989_000_000',status: 'Aprove', img: 'images/user.jpg', email: 'ali@gmail.com'},
    {id: 2, customer: 'Qadir Yolme',date: '10 jun 2022', Amount: '$123_000',status: 'Aprove', img: 'images/index2.jpg', email: 'ali@gmail.com'},
    {id: 3, customer: 'sara Mortazavi',date: '6 Apr 1999', Amount: '$80_000_000',status: 'Declined', img: 'images/user.jpg', email: 'ali@gmail.com'},
    {id: 4, customer: 'Mohammad Amin',date: '12 May 2022', Amount: '$980_000',status: 'Aprove', img: 'images/index.jpg', email: 'ali@gmail.com'},
    {id: 5, customer: 'Sajjad Heydari',date: '5 Agu 2020', Amount: '$1_000_000',status: 'Pending', img: 'images/index2.jpg', email: 'ali@gmail.com'},
]

let userRows = [
    {id: 1, username: 'Mahdiye Kaffash', avatar: 'images/user.jpg', status: 'active', email: 'ali@gmail.com', transaction: '$129.52'},
    {id: 2, username: 'sara eslami', avatar: 'images/user.jpg', status: 'not_active', email: 'ali@gmail.com', transaction: '$1.5'},
    {id: 3, username: 'ali bostani', avatar: 'images/user.jpg', status: 'active', email: 'ali@gmail.com', transaction: '$16.82'},
    {id: 4, username: 'Mahya Hami', avatar: 'images/user.jpg', status: 'not_active', email: 'ali@gmail.com', transaction: '$19.58'},
    {id: 5, username: 'Mohammad Reza', avatar: 'images/user.jpg', status: 'active', email: 'ali@gmail.com', transaction: '$14'},
]



let products = [
    {id:1, title: 'Asuse', avatar: 'images/Asus.jpg', price: 129_000},
    {id:2, title: 'Lenovo', avatar: 'images/lenovo.jpg', price: 19_000},
    {id:3, title: 'Dell', avatar: 'images/dell.jpg', price: 12_000},
    {id:4, title: 'Aser', avatar: 'images/aser.jpg', price: 88_000},
]

const productsData = [
    {name: 'Jan', sales: 4000},
    {name: 'Feb', sales: 3000},
    {name: 'Mar', sales: 5000},
]

export {xAxisData, users, transAction, userRows, products, productsData}