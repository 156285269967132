import React from "react"
import {
            ResponsiveContainer,
            LineChart,
            Line,
            XAxis,
            CartesianGrid,
            Tooltip
        } from 'recharts';


import'./Chart.css'

function Chart({title, data, datakey, grid}) {
    return(
        <>
            <div className="chart">
                <h3 className="chartTitle">{title}</h3>
                <ResponsiveContainer width='100%' aspect={4}>
                    <LineChart data={data}>
                        <XAxis dataKey="name" stroke='#555ddd' />
                        <Line type='monotone' dataKey={datakey} stroke='#555ddd' />
                        <Tooltip />
                        {grid && <CartesianGrid stroke='#e0dfdf' strokeDasharray='10'/>}
                    </LineChart>
                </ResponsiveContainer>
            </div>
        </>
    )
}

export default Chart