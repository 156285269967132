// config.js

const API_BASE_URL = 'https://api.example.com';
const API_VERSION = 'v1';


const hostname = "https://api.bettermove.ir";
//const hostname = "https://api.bettermove.ir" ;
const API_ENDPOINTS = {
  GET_USERS: `${API_BASE_URL}/${API_VERSION}/users`,
  POST_USER: `${API_BASE_URL}/${API_VERSION}/users`,
  // دیگر اندپوینت‌ها...
};
let jwt_token="";
const localStorageData = localStorage.getItem('user');
if (localStorageData) {
  const parsedData = JSON.parse(localStorageData);
  jwt_token = parsedData.token;
}else{
  jwt_token="no";
}



export default {
  API_BASE_URL,
  API_VERSION,
  API_ENDPOINTS,
  jwt_token,
  hostname
};
