import React, { useState } from "react";
import "./subscriptions.css";
import { Button } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Searchbox from "./../../Components/Searchbox/Searchbox";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import { Edit, Delete } from "@mui/icons-material";
import Swal from "sweetalert2";
import axios from "axios";
import config from "../../config";
import EditModal from "./EditModal";

export default function Subscriptions() {
  const [Idd, setIdd] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [allSubscriptions, setAllSubscriptions] = useState([
    {
      id: 1,
      title: "اشتراک دو هفته ای رایگان",
      price: 0,
      status: true,
      features: `"دسترسی به تمامی ویدئوهای آموزشی و تمرینی به مدت دو هفته",
        "ایجاد و مدیریت پکیج‌های تمرینی برای بیماران",`,
    },
    {
      id: 2,
      title: "اشتراک سالانه پزشکان",
      price: 4_500_000,
      status: true,
      features: `"دسترسی به ویدئوهای انحصاری و جدیدترین محتواهای آموزشی",
        "تمامی امکانات اشتراک ماهانه به همراه تخفیف ویژه سالانه",`,
    },
    {
      id: 3,
      title: "اشتراک ماهانه پزشکان",
      price: 4_000,
      status: false,
      features: `"دسترسی به تمامی ویدئوهای آموزشی و تمرینی",
        "ایجاد و مدیریت پکیج‌های تمرینی برای بیماران",
        "استفاده از سیستم تیکتینگ برای ارتباط با بیماران و ادمین"`,
    },
  ]);
  function handleEditClick(id) {
    setIdd(id);
    setIsEditModalOpen(true);
  }

  function closeEditModal() {
    setIsEditModalOpen(false);
  }

  function deleteSubscription(id) {
    Swal.fire({
      icon: "question",
      title: "حذف اشتراک",
      text: "آیا از حذف اشتراک مطمئن هستید؟",
      showCancelButton: true,
      confirmButtonText: "بله",
      cancelButtonText: "خیر",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let jwt_token = "";
        const localStorageData = localStorage.getItem("user");

        if (localStorageData) {
          const parsedData = JSON.parse(localStorageData);
          jwt_token = parsedData.token;
          const headers = {
            Authorization: `Bearer ${jwt_token}`,
          };

          // await axios
          //   .delete(config.hostname + `/api/v1/education/${educationId}`, {
          //     headers,
          //   })

          //   .catch((error) => {
          //     console.error("خطا در درخواست به API برای حذف پزشک: ", error);
          //   });
        } else {
          Swal.fire({
            title: "خطا",
            text: "مشکلی در حذف اشتراک پیش آمد.",
            icon: "error",
          });
        }
      }
    });
  }

  const columns = [
    {
      field: "id",
      headerName: "شناسه",
      width: 90,
    },
    {
      field: "action",
      headerName: "عملیات",
      width: 150,
      renderHeader: () => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <span style={{ marginTop: "1px" }}>عملیات</span>
        </div>
      ),
      renderCell: (params) => {
        return (
          <>
            {
              <Link
                className="link"
                onClick={() => handleEditClick(params.row.id)}
              >
                <Edit className="userListEdit" />
              </Link>
            }
            {
              <Link className="link">
                <Delete
                  className="userListTrash"
                  onClick={() => deleteSubscription(params.row.id)}
                />
              </Link>
            }
          </>
        );
      },
    },
    {
      field: "title",
      headerName: "عنوان",
      width: 150,
    },
    {
      field: "price",
      headerName: "قیمت",
      width: 150,
    },
    {
      field: "features",
      headerName: "ویژگی ها",
      width: 200,
    },
    {
      field: "status",
      headerName: "وضعیت",
      width: 200,
      renderCell: (params) => {
        return <span>{params.row.status === true ? "فعال" : "غیرفعال"}</span>;
      },
    },
  ];
  return (
    <main className="userList">
      <div className="education-actions">
        <Button onClick={() => handleEditClick(0)} variant="contained">
          افزودن
          <AddCircleOutlineIcon className="userListInsert" />
        </Button>
        <Searchbox />
      </div>

      <DataGrid
        rows={allSubscriptions}
        columns={columns}
        pageSize={10}
        disableSelectionOnClick
        checkboxSelection
      />

      <EditModal
        Idd={Idd}
        isOpen={isEditModalOpen}
        onClose={() => closeEditModal()}
      />
    </main>
  );
}
