import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Modal,
  Paper,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import config from "../../config";

export default function EditModal({ Idd, isOpen, onClose }) {
  const [subscription, setSubscription] = useState({
    title: "",
    price: "",
    status: false,
    features: "",
  });

  useEffect(() => {
    if (Idd > 0) {
      let jwt_token = "";
      const localStorageData = localStorage.getItem("user");
      if (localStorageData) {
        const parsedData = JSON.parse(localStorageData);
        jwt_token = parsedData.token;
      } else {
        jwt_token = "no";
      }

      const authToken = jwt_token;
      const headers = {
        Authorization: `Bearer ${authToken}`,
      };
      //   axios
      //     .get(config.hostname + `/api/v1/Exercise/${Idd}`, { headers })
      //     .then((response) => {
      //       const exercisesDataaa = response.data;
      //       setSubscription({
      //         name: exercisesDataaa.name,
      //         subject1: exercisesDataaa.subject1,
      //         subject2: exercisesDataaa.subject2,
      //         preparation: exercisesDataaa.preparation,
      //         execution: exercisesDataaa.execution,
      //       });
      //     })
      //     .catch((error) => {
      //       console.error("خطا در درخواست به API: ", error);
      //     });
    } else {
      setSubscription({
        title: "",
        price: "",
        status: false,
        features: "",
      });
    }
  }, [Idd]);

  async function updateSubscription() {
    try {
      let jwt_token = "";
      const localStorageData = localStorage.getItem("user");
      if (localStorageData) {
        const parsedData = JSON.parse(localStorageData);
        jwt_token = parsedData.token;
        const headers = {
          Authorization: `Bearer ${jwt_token}`,
        };
        // const response = await axios.put(
        //   config.hostname + `/api/v1/Exercise`,
        //   {
        //     keyID: Idd,
        //     name: exercisesData.name,
        //     subject1: "نحوه اجرای تمرین",
        //     subject2: "نکات تمرین",
        //     preparation: exercisesData.preparation,
        //     execution: exercisesData.execution,
        //     otherDescription: exercisesData.execution,
        //     exercisesCategoryID: -1,
        //   },
        //   { headers }
        // );
      } else {
        jwt_token = "no";
      }
    } catch (error) {
      console.error("خطا در ویرایش کاربر: ", error);
    }
  }

  async function insertSubscription() {
    try {
      let jwt_token = "";
      const localStorageData = localStorage.getItem("user");
      if (localStorageData) {
        const parsedData = JSON.parse(localStorageData);
        jwt_token = parsedData.token;

        const headers = { Authorization: `Bearer ${jwt_token}` };

        //   const response = await axios.post(
        //     config.hostname + `/api/v1/Exercise`,
        //     {
        //       keyID: -1,
        //       name: exercisesData.name,
        //       subject1: "نحوه اجرای تمرین",
        //       subject2: "نکات تمرین",
        //       preparation: exercisesData.preparation,
        //       execution: exercisesData.execution,
        //       otherDescription: exercisesData.execution,
        //       exercisesCategoryID: -1,
        //     },
        //     { headers }
        //   );
      } else {
        jwt_token = "no";
      }
    } catch (error) {
      console.error("خطا در ویرایش کاربر: ", error);
    }
  }

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Paper
        sx={{
          position: "absolute",
          display: "flex",
          flexDirection: "row",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
          padding: 2,
        }}
      >
        <div style={{ flex: 1, padding: "0 10px" }}>
          <h2>{Idd > 0 ? "ویرایش اشتراک" : "ایجاد اشتراک"}</h2>
          <hr style={{ borderTop: "2px solid blue", margin: "10px 0" }} />

          <TextField
            itemID="subscriptionTitle"
            label="عنوان اشتراک"
            value={subscription.title}
            onChange={(e) =>
              setSubscription({ ...subscription, title: e.target.value })
            }
            fullWidth
            style={{ marginTop: "10px" }}
            dir="rtl"
          />

          <TextField
            itemID="subscriptionPrice"
            label="قیمت اشتراک"
            value={subscription.price}
            onChange={(e) =>
              setSubscription({ ...subscription, price: e.target.value })
            }
            fullWidth
            style={{ marginTop: "10px" }}
            dir="rtl"
          />

          <TextField
            itemID="subscriptionFeatures"
            label="ویژگی های اشتراک"
            value={subscription.features}
            onChange={(e) =>
              setSubscription({
                ...subscription,
                features: e.target.value,
              })
            }
            multiline
            rows={6}
            fullWidth
            style={{ marginTop: "10px" }}
            dir="rtl"
          />

          <FormControl fullWidth style={{ marginTop: "10px" }}>
            <FormLabel>وضعیت</FormLabel>
            <RadioGroup
              row
              aria-label="status"
              name="status"
              value={subscription.status ? "فعال" : "غیرفعال"}
              onChange={(e) => {
                if (Boolean(e.target.value) === true) {
                  setSubscription({ ...subscription, status: true });
                } else if (Boolean(e.target.value) === false) {
                  setSubscription({ ...subscription, status: false });
                }
              }}
            >
              <FormControlLabel value={true} control={<Radio />} label="فعال" />
              <FormControlLabel
                value={false}
                control={<Radio />}
                label="غیرفعال"
              />
            </RadioGroup>
          </FormControl>

          <Button
            variant="contained"
            onClick={Idd > 0 ? updateSubscription : insertSubscription}
            sx={{ marginTop: 2 }}
          >
            {Idd > 0 ? "ویرایش اشتراک" : "ایجاد اشتراک"}
          </Button>
        </div>
      </Paper>
    </Modal>
  );
}
