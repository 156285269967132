import React from "react";
import Featureas from "../../Components/featureas/Featureas";
import Chart from "../../Components/chart/Chart";
import { xAxisData } from "../../datas";
import WidgetSm from "../../Components/widgetSm/WidgetSm";
import WidgetLg from "../../Components/WidgetLg/WidgetLg";

import "./Home.css";
import TransactionBox from "../../Components/transactionBox/TransactionBox";

function Home() {
  return (
    <>
      <div className="home">
        {/* <Featureas />
                <Chart grid title='Month Sale' data={xAxisData} datakey='Sale'/> */}
        {/* <div className="widgetContainer">
                    <WidgetSm />
                    <WidgetLg />
                </div>*/}
        <div className="transaction-boxes">
          <TransactionBox title="پرداختی های جدید" value="8" />
          <TransactionBox title="ثبت نام های جدید" value="10" />
          <TransactionBox title="پیام های خوانده نشده" value="2" />
          <TransactionBox title="ارسال پیام" value="0" />
        </div>
      </div>
    </>
  );
}

export default Home;
