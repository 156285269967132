import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
//import { Modal, Paper, TextField, Button } from "@mui/material";
import {
  Modal,
  Paper,
  TextField,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  MenuItem,
} from "@mui/material";
import Swal from "sweetalert2";
import axios from "axios";
import { ContentPasteOffSharp, Email } from "@mui/icons-material";
import config from "../../config";
import FileUploader2 from "../../Components/files/FileUploader2";
import PersianDatePicker from "../../Components/PersianDatePicker";
import jalaliMoment from "jalali-moment";
import {
  validateEmail,
  validateName,
  validatePassword,
  validatePasswordRepeat,
  validatePhone,
  validateUsername,
} from "../../validation/validation";

function ModalUser({ isOpen, onClose, Idd }) {
  // const [fName, setfName] = useState(null);
  // const [lName, setlName] = useState(null);
  // const [nName, setnName] = useState(null);
  //// const [medicalLicense, setmedicalLicense] = useState(null);
  //const famousCities = ["رشت", "زاهدان", "تهران", "مشهد", "تبریز"];
  const [selectedMiladiDate, setSelectedMiladiDate] = useState(null);

  // Step 2: Pass a callback function to YourComponent
  const handleDateChange = (miladiDate) => {
    setSelectedMiladiDate(miladiDate);
  };

  //
  const [selectedGregorianDate, setSelectedGregorianDate] = useState(null);
  // const [userType, setUserType] = useState("patient");

  // Callback function to update the selectedGregorianDate
  const handleGregorianDateChange = (gregorianDate) => {
    setSelectedGregorianDate(gregorianDate);

    // مقدار تاریخ میلادی را در اینجا می‌توانید چاپ کنید
    console.log("Gregorian Date in App.js:", gregorianDate);
  };
  //

  const [educationData, setEducationData] = useState({
    id: "",
    firstname: "",
    lastname: "",
    username: "",
    password: "",
    passwordRepeat: "",
    email: "",
    phoneNumber: "",
    userType: "patient",
  });

  const [firstnameError, setFirstnameError] = useState("");
  const [lastnameError, setLastnameError] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [passwordRepeatError, setPasswordRepeatError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [patientList2, setPatientList2] = useState([]);
  const [patientList, setPatientList] = useState([]);
  const [DoctorList2, setDoctorList2] = useState([]);
  const [doctorList, setDoctorList] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [password, setpassword] = useState(null);
  const [passwordRepeat, setpasswordRepeat] = useState(null);

  useEffect(() => {
    const {
      firstname,
      lastname,
      username,
      email,
      phoneNumber,
      password,
      passwordRepeat,
    } = educationData;

    setFirstnameError(validateName(firstname, "نام", 3));
    setLastnameError(validateName(lastname, "نام خانوادگی", 4));
    setUsernameError(validateUsername(username));
    setPasswordError(validatePassword(password, 8));
    setPasswordRepeatError(validatePasswordRepeat(passwordRepeat, password));
    setEmailError(validateEmail(email));
    setPhoneNumberError(validatePhone(phoneNumber));
  }, [educationData]);

  const convertGregorianToShamsi = (gregorianDate) => {
    // Use jalali-moment to convert Gregorian to Shamsi
    const shamsiDate = jalaliMoment(gregorianDate).format("jYYYY-jMM-jDD");
    return shamsiDate;
  };

  useEffect(() => {
    console.log(Idd);
    if (Idd) {
      // const confirmDelete3 = window.confirm(
      //      "ddddd1"
      //   );

      // if (confirmDelete3) {}

      let jwt_token = "";
      const localStorageData = localStorage.getItem("user");
      if (localStorageData) {
        const parsedData = JSON.parse(localStorageData);
        jwt_token = parsedData.token;
      } else {
        jwt_token = "no";
      }

      const authToken = jwt_token;
      const headers = {
        Authorization: `Bearer ${authToken}`,
      };
      axios
        //https://api.bettermove.ir/api/v1/User/1
        .get(config.hostname + `/api/v1/User/${Idd}`, { headers })
        .then((response) => {
          const educationDataaa = response.data;
          console.log("1" + educationDataaa.educationDate);
          setEducationData({
            id: educationDataaa.id,
            username: educationDataaa.username,
            email: educationDataaa.email,
            phoneNumber: educationDataaa.phoneNumber,
          });
          setpassword("");
          setpasswordRepeat("");
        })
        .catch((error) => {
          console.error("خطا در درخواست به API: ", error);
        });

      const initialGregorianDate = "2023-01-01";

      // Convert the initial Gregorian date to Shamsi (Jalali)
      //const selectedMiladiDate = educationData.educationDate;
    } else {
      setEducationData({
        id: "",
        firstname: "",
        lastname: "",
        username: "",
        password: "",
        passwordRepeat: "",
        email: "",
        phoneNumber: "",
        userType: "patient",
      });
      let jwt_token = "";
      const localStorageData = localStorage.getItem("user");
      if (localStorageData) {
        const parsedData = JSON.parse(localStorageData);
        jwt_token = parsedData.token;
      } else {
        jwt_token = "no";
      }

      const authToken = jwt_token;
      const headers = {
        Authorization: `Bearer ${authToken}`,
      };
      axios
        .get(config.hostname + "/api/v1/Patient/1,1000", { headers })
        .then((response) => {
          // باز کردن پاسخ و استخراج لیست بیماران
          const patientsData = response.data;
          // ست کردن مقادیر در استیت patientList
          // setPatientList2(patientsData);
          const patientListTemp = patientsData.patientModelList.map(
            (patient) => `${patient.fName} ${patient.lName}`
          );
          const patientListIDTemp = patientsData.patientModelList.map(
            (patient) => `${patient.keyID}`
          );
          const patients = patientsData.patientModelList.map((patient) => ({
            id: patient.keyID,
            name: `${patient.fName} ${patient.lName}`,
          }));

          setPatientList(patients);
        })
        .catch((error) => {
          console.error("خطا در درخواست به API: ", error);
        });

      axios
        .get(config.hostname + "/api/v1/Physician/1,1000", { headers })
        .then((response) => {
          // باز کردن پاسخ و استخراج لیست بیماران
          const DoctorListData = response.data;
          // ست کردن مقادیر در استیت patientList
          setDoctorList2(DoctorListData);
          const DoctorList3 = DoctorList2.physicianModelList?.map(
            (Doctor) => `${Doctor.fName} ${Doctor.lName}`
          );

          const doctors = DoctorListData.physicianModelList.map((dr) => ({
            id: dr.keyID,
            name: `${dr.fName} ${dr.lName}`,
          }));
          setDoctorList(doctors);
        })
        .catch((error) => {
          console.error("خطا در درخواست به API: ", error);
        });
    }
  }, [Idd]);

  const updateUser = async () => {
    console.log("update");

    // console.log('newCategorise:', newCategorise)
    try {
      let jwt_token = "";
      const localStorageData = localStorage.getItem("user");
      if (localStorageData) {
        const parsedData = JSON.parse(localStorageData);
        jwt_token = parsedData.token;
      } else {
        jwt_token = "no";
      }

      const authToken = jwt_token;
      // console.log("eeddd");
      const headers = {
        Authorization: `Bearer ${authToken}`,
      };

      const response = axios.put(
        config.hostname + `/api/v1/User/changePassword`,
        {
          userid: Idd,
          oldpass: "str",
          password: password,
          confirmPassword: passwordRepeat,
        },
        { headers }
      );

      console.log("تغییر رمز با موفقیت انجام شد", response.data);

      onClose();
    } catch (error) {
      console.error("خطا در تغییر رمز کاربر: ", error);
    }

    /*console.log("update");
    try {
      let jwt_token = "";
      const localStorageData = localStorage.getItem("user");
      if (localStorageData) {
        const parsedData = JSON.parse(localStorageData);
        jwt_token = parsedData.token;
      } else {
        jwt_token = "no";
      }

      const authToken = jwt_token;
      console.log("eeddd");
      const headers = {
        Authorization: `Bearer ${authToken}`,
      };
      //https://api.bettermove.ir/api/v1/User/updateInfo

      const response = await axios.put(
        config.hostname + `/api/v1/User/updateInfo`,
        {
          userid: Idd,
          newUsername: educationData.username,
          phonenumber: educationData.phoneNumber,
          email: educationData.email,
          password: "",
          confirmPassword: "",
        },
        { headers }
      );

      console.log("ویرایش با موفقیت انجام شد", response.data);

      onClose();
    } catch (error) {
      console.error("خطا در ویرایش کاربر: ", error);
    }*/
  };
  const handleTypeChange = (e) => {
    setEducationData({ ...educationData, username: e.target.value });
  };

  const [hasDoctorUsername, setHasDoctorUsername] = useState(false);

  async function Getusername(personKeyID, role) {
    console.log(personKeyID, role);

    try {
      let jwt_token = "";
      const localStorageData = localStorage.getItem("user");
      if (localStorageData) {
        const parsedData = JSON.parse(localStorageData);
        jwt_token = parsedData.token;
      } else {
        jwt_token = "no";
      }

      const authToken = jwt_token;
      const headers = {
        Authorization: `Bearer ${authToken}`,
      };

      if (role === "doctor") {
        const response = await axios.post(
          `${config.hostname}/api/v1/User/getusername`,
          {
            personKeyID,
            role: role === "doctor" ? "Admin" : "patient",
          },
          { headers }
        );
        const username = response.data.usesrname;

        console.log(username);

        if (username) {
          setEducationData({ ...educationData, username });
          setHasDoctorUsername(true);
        } else {
          setHasDoctorUsername(false);
        }
        // onClose();
      }
    } catch (error) {
      console.error("خطا در دریافت پزشک: ", error);
    }
  }

  const insertuser = async () => {
    console.log(selectedId);

    const newUser = null;

    console.log(newUser);

    try {
      let jwt_token = "";
      const localStorageData = localStorage.getItem("user");
      if (localStorageData) {
        const parsedData = JSON.parse(localStorageData);
        jwt_token = parsedData.token;
      } else {
        jwt_token = "no";
      }
      const authToken2 = jwt_token;
      const headers = { Authorization: `Bearer ${authToken2}` };

      const response = await axios.post(
        `${config.hostname}/api/v1/User/Register`,
        {
          role: educationData.userType === "doctor" ? "admin" : "patient",
          userName: educationData.username,
          password: educationData.password,
          confirmPassword: educationData.passwordRepeat,
          personKeyID:
            educationData.userType === "doctor"
              ? educationData.doctorValue
              : educationData.patientValue,
          personFullName:
            educationData.userType === "doctor"
              ? doctorList.find(
                  (doctor) => doctor.id === educationData.doctorValue
                )?.name
              : patientList.find(
                  (patient) => patient.id === educationData.patientValue
                )?.name,
        },
        { headers }
      );

      onClose()

      Swal.fire({
        title: "کاربر با موفقیت اضافه شد",
        icon: "success",
        confirmButtonText: "عالی",
      })

    } catch (error) {
      console.error("خطا در اضافه کردن پزشک: ", error);
    }
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Paper
        sx={{
          position: "absolute",
          display: "flex",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
          padding: 2,
        }}
      >
        <div style={{ flex: 1, padding: "0 10px" }}>
          <h2>{Idd ? "تغییر رمز عبور" : "ایجاد کاربر"}</h2>
          <hr style={{ borderTop: "2px solid blue", margin: "10px 0" }} />

          <FormControl disabled={!!Idd}>
            <FormLabel>نوع کاربر</FormLabel>
            <RadioGroup
              row
              aria-label="userType"
              name="userType"
              value={educationData.userType}
              //  onChange={(e) => handleTypeChange(e)}

              onChange={(e) => {
                setEducationData({
                  ...educationData,
                  userType: e.target.value,
                });
              }}
            >
              <FormControlLabel
                value="patient"
                control={<Radio />}
                label="بیمار"
              />
              <FormControlLabel
                value="doctor"
                control={<Radio />}
                label="پزشک"
              />
            </RadioGroup>
          </FormControl>

          <FormControl disabled={!!Idd} fullWidth style={{ marginTop: "10px" }}>
            <FormLabel>
              {educationData.userType === "doctor"
                ? "لیست پزشکان"
                : "لیست بیماران"}
            </FormLabel>
            <Select
              value={
                selectedId ||
                (educationData.userType === "doctor"
                  ? doctorList[0]?.id
                  : patientList[0]?.id)
              }
              onChange={(e) => {
                setSelectedId(e.target.value);
                setEducationData({
                  ...educationData,
                  doctorValue: e.target.value,
                  patientValue: e.target.value,
                  //username : `user${e.target.value*100}`,
                  username:
                    educationData.userType === "doctor"
                      ? ""
                      : `user${e.target.value * 100}`,
                });

                Getusername(e.target.value, educationData.userType);
              }}
              fullWidth
            >
              {educationData.userType === "doctor"
                ? doctorList.map((doctor, index) => (
                    <MenuItem key={index} value={doctor.id}>
                      {doctor.name}
                    </MenuItem>
                  ))
                : patientList.map((patient, index) => (
                    <MenuItem key={index} value={patient.id}>
                      {patient.name}
                    </MenuItem>
                  ))}
            </Select>
          </FormControl>
          <TextField
            itemID="username"
            label="نام کاربری"
            value={educationData.username}
            onChange={(e) =>
              setEducationData({ ...educationData, username: e.target.value })
            }
            error={!!usernameError && educationData.userType !== "patient"}
            helperText={usernameError && usernameError}
            fullWidth
            style={{ marginTop: "10px" }}
            dir="rtl"
            disabled={
              educationData.userType === "patient" || !!Idd || hasDoctorUsername
            }
          />

          <TextField
            itemID="phoneNumber"
            label="شماره تماس"
            value={educationData.phoneNumber}
            onChange={(e) =>
              setEducationData({
                ...educationData,
                phoneNumber: e.target.value,
              })
            }
            error={!!phoneNumberError}
            helperText={phoneNumberError && phoneNumberError}
            fullWidth
            style={{ marginTop: "10px", display: "none" }}
            dir="rtl"
          />
          <TextField
            itemID="email"
            label="ایمیل"
            value={educationData.email}
            onChange={(e) =>
              setEducationData({ ...educationData, email: e.target.value })
            }
            error={!!emailError}
            helperText={emailError && emailError}
            fullWidth
            style={{ marginTop: "10px", display: "none" }}
            dir="rtl"
          />

          <TextField
            itemID="educationCategoryID"
            label="شناسه دسته‌بندی آموزش"
            value={educationData.educationCategoryID}
            onChange={(e) =>
              setEducationData({
                ...educationData,
                educationCategoryID: e.target.value,
              })
            }
            fullWidth
            style={{ marginTop: "10px", display: "none" }}
            dir="rtl"
          />
        </div>
        <div style={{ flex: 1, padding: "0 10px" }}>
          <br></br>
          <br></br> <br></br>
          <TextField
            itemID="password"
            label="رمزعبور"
            value={educationData.password}
            onChange={(e) =>
              setEducationData({
                ...educationData,
                password: e.target.value,
              })
            }
            error={!!passwordError}
            helperText={passwordError && passwordError}
            fullWidth
            style={{ marginTop: "10px" }}
            dir="rtl"
            disabled={hasDoctorUsername}
          />
          <TextField
            itemID="passwordRepeat"
            label="تکرار رمزعبور"
            value={educationData.passwordRepeat}
            onChange={(e) =>
              setEducationData({
                ...educationData,
                passwordRepeat: e.target.value,
              })
            }
            error={!!passwordRepeatError}
            helperText={passwordRepeatError && passwordRepeatError}
            fullWidth
            style={{ marginTop: "10px" }}
            dir="rtl"
            disabled={hasDoctorUsername}
          />
          <Button
            disabled={hasDoctorUsername} //isNewUserButtonDisabled
            variant="contained"
            onClick={Idd ? updateUser : insertuser}
            sx={{ marginTop: 4 }}
          >
            {Idd ? "تغییر رمز عبور" : "ایجاد کاربر"}
          </Button>
        </div>
      </Paper>
    </Modal>
  );
}
//style={{ marginTop: '10px', display: Idd > 1 ? 'block' : 'none'}}

//<div  style={{ marginTop: '10px' ,height:'200px'}} >

//<PersianDatePicker   onDateChange={handleGregorianDateChange} />

//{ نمایش مقدار به‌روزرسانی شده در App.js }
//{selectedGregorianDate && <p>Gregorian Date in App.js: {selectedGregorianDate}</p>}

//</div

export default ModalUser;
