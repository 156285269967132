import { Search } from "@mui/icons-material";
import React, { useState } from "react";

import "./Searchbox.css";

export default function Searchbox() {
  const [searchValue, setSearchValue] = useState("");

  return (
    <div className="search-box">
   {/*   <input
        onChange={(e) => setSearchValue(e.target.value)}
        value={searchValue}
        type="text"
        placeholder="جستجو"
      />
   <Search />*/}
    </div>
  );
}
