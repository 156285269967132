import React, { useState, useEffect } from "react";
import DisplayImages from "../Exercises/DisplayImage";
import { Modal, Paper, TextField, Button } from "@mui/material";
import axios from "axios";
import config from "../../config";
import FileUploader3 from "../../Components/files/FileUploader3";

function ModalAttachEduFile({ isOpen, onClose, Idd }) {
  const [imagesrc, setImage] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  console.log(uploading);

  const initialFileList = [
    {
      FileName: "",
      FileContent: "",
      FileTitle: "",
      FileDescription: "",
      FilesAddress: "",
      FileType: "",
      isImage: false,
    },
  ];
  const [educationFilenewList, seteducationFilenewList] =
    useState(initialFileList);
  const [educationFileList, setEducationFIleList] = useState(initialFileList);

  const handleFileChange = (files) => {
    setSelectedFiles(
      files.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.path === value.path)
      )
    );
  };

  const [educationData, setEducationData] = useState({
    title: "",
    content: "",
    subTitle: "",
    subContent: "",
    educationDate: "",
    educationCategoryID: 0,
  });

  useEffect(() => {
    setSelectedFiles([]);
    seteducationFilenewList([]);
    // setEducationFIleList([]);
    setEducationData({});
    if (Idd > 0) {
      let jwt_token = "";
      const localStorageData = localStorage.getItem("user");
      if (localStorageData) {
        const parsedData = JSON.parse(localStorageData);
        jwt_token = parsedData.token;
      } else {
        jwt_token = "no";
      }

      const authToken = jwt_token;
      const headers = {
        Authorization: `Bearer ${authToken}`,
      };
      axios
        .get(config.hostname + `/api/v1/Education/${Idd}`, { headers })
        .then((response) => {
          // const {
          //   title,
          //   content,
          //   subTitle,
          //   subContent,
          //   educationDate,
          //   educationCategoryID,
          // } = response.data;

          setEducationData({
            ...response.data,
          });
        })
        .catch((error) => {
          console.error("خطا در درخواست به API: ", error);
        });

      axios
        .get(config.hostname + `/api/v1/Education/${Idd}/EducationFiles`, {
          headers,
        })
        .then((response) => {
          const educationfilData = response.data.result;
          const formattedData = educationfilData.map((item) => ({
            KeyID: item.keyID,
            FileName: item.fileName,
            FileContent: item.fileContent,
            FileTitle: item.fileTitle,
            FileDescription: item.fileDescription,
            FilesAddress: item.filesAddress,
            FileType: item.fileType,
            isImage: item.isImage,
          }));
          seteducationFilenewList(formattedData);
        })
        .catch((error) => {
          console.error("خطا در درخواست به API: ", error);
        });
    } else {
      setEducationData({
        title: "",
        content: "",
        subTitle: "",
        subContent: "",
        educationDate: "",
        educationCategoryID: 0,
      });
    }
  }, [Idd]);

  const loadFormData = () => {
    setSelectedFiles([]);
    seteducationFilenewList([]);
    setEducationFIleList([]);
    let jwt_token = "";
    const localStorageData = localStorage.getItem("user");
    if (localStorageData) {
      const parsedData = JSON.parse(localStorageData);
      jwt_token = parsedData.token;
    } else {
      jwt_token = "no";
    }

    const authToken = jwt_token;
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    axios
      .get(config.hostname + `/api/v1/Exercise/${Idd}/EducationFiles`, {
        headers,
      })
      .then((response) => {
        const educationfilData = response.data;

        const formattedData = educationfilData.map((item) => ({
          KeyID: item.keyID,
          FileName: item.fileName,
          FileContent: item.fileContent,
          FileTitle: item.fileTitle,
          FileDescription: item.fileDescription,
          FilesAddress: item.filesAddress,
          FileType: item.fileType,
          isImage: item.isImage,
        }));
        seteducationFilenewList(formattedData);
        console.log(formattedData);
      })
      .catch((error) => {
        console.error("خطا در درخواست به API: ", error);
      });
  };

  const updateUser = async () => {
    try {
      let jwt_token = "";
      const localStorageData = localStorage.getItem("user");
      if (localStorageData) {
        const parsedData = JSON.parse(localStorageData);
        jwt_token = parsedData.token;
      } else {
        jwt_token = "no";
      }

      const authToken = jwt_token;

      const headers = {
        Authorization: `Bearer ${authToken}`,
      };

      const response = await axios.put(
        config.hostname + `/api/v1/Education`,
        {
          keyID: Idd,
          educationData,
        },
        { headers }
      );

      console.log("ویرایش با موفقیت انجام شد", response.data);

      onClose();
    } catch (error) {
      console.error("خطا در ویرایش کاربر: ", error);
    }
  };

  const handleDeleteFile = (fileData, isImage) => {
    console.log("test for deleting file", fileData, Idd);
    const confirmDelete = window.confirm(
      "آیا مطمئن هستید که می‌خواهید این فایل را حذف کنید؟"
    );

    if (confirmDelete) {
      let jwt_token = "";
      const localStorageData = localStorage.getItem("user");
      if (localStorageData) {
        const parsedData = JSON.parse(localStorageData);
        jwt_token = parsedData.token;
      } else {
        jwt_token = "no";
      }
      const authToken = jwt_token;
      const headers = {
        Authorization: `Bearer ${authToken}`,
      };
      axios
        .delete(
          config.hostname +
            `/api/v1/Exercise/${Idd}/ExerciseFiles/${fileData},${isImage}`,
          { headers }
        )
        .then((response) => {
          console.log("فایل با موفقیت حذف شد", response.data);

          const updatedFileList = educationFilenewList.filter(
            (item) => item.KeyID !== fileData
          );
          seteducationFilenewList(updatedFileList);
          console.log(updatedFileList);
        })
        .catch((error) => {
          console.error("خطا در درخواست به API برای حذف فایل: ", error);
        });
      // refreshlist();
    }
  };

  const insertFiles = async () => {
    setUploading(true);

    try {
      const filesData = [];
      const promises = selectedFiles.map(async (file) => {
        return new Promise((resolve) => {
          const fileData = new FormData();
          const reader = new FileReader();
          reader.onload = () => {
            const fileBytes = new Uint8Array(reader.result);
            fileData.fileName = file.name;
            fileData.fileContent = Array.from(fileBytes).join(",");
            fileData.FileTitle = "title";
            fileData.FileDescription = "description";
            fileData.FilesAddress = "";
            fileData.keyID = 0;
            fileData.FileType = file.name.split(".").pop();
            fileData.isImage =
              fileData.FileType.toUpperCase() === "JPG" ||
              fileData.FileType.toUpperCase() === "PNG" ||
              false;
            filesData.push(fileData);
            resolve();
          };
          reader.readAsArrayBuffer(file);
        });
      });

      await Promise.all(promises);

      let jwt_token = "";
      const localStorageData = localStorage.getItem("user");
      if (localStorageData) {
        const parsedData = JSON.parse(localStorageData);
        jwt_token = parsedData.token;
      } else {
        jwt_token = "no";
      }

      const authToken = jwt_token;
      const headers = {
        Authorization: `Bearer ${authToken}`,
      };

      const response = await axios.post(
        config.hostname + `/api/v1/Education/${Idd}/EducationFiles`,
        filesData,
        {
          headers,
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );

            setUploadProgress(percentCompleted);
            console.log(`Progress: ${percentCompleted}`);
          },
        }
      );

      console.log("ویرایش با موفقیت انجام شد", response);
      loadFormData();
      onClose();
    } catch (error) {
      console.error("خطا در ویرایش کاربر: ", error);
    } finally {
      setUploading(false);
    }
  };

  return (
    <Modal className="add-education-modal" open={isOpen} onClose={onClose}>
      <Paper
        sx={{
          position: "absolute",
          display: "flex",
          flexDirection: "row",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
          padding: 2,
        }}
      >
        <div style={{ flex: 1, padding: "0 10px" }}>
          <h2>افزودن فایل های آموزش</h2>
          <hr style={{ borderTop: "2px solid blue", margin: "10px 0" }} />

          <TextField
            itemID="educationTitle"
            label="عنوان آموزش"
            value={educationData.title}
            onChange={(e) =>
              setEducationData({ ...educationData, title: e.target.value })
            }
            fullWidth
            style={{ marginTop: "10px" }}
            dir="rtl"
            labelDirection="rtl"
            InputProps={{
              readOnly: true,
            }}
          />

          <TextField
            itemID="educationContent"
            label="محتوای آموزش"
            value={educationData.content}
            onChange={(e) =>
              setEducationData({ ...educationData, content: e.target.value })
            }
            fullWidth
            style={{ marginTop: "10px" }}
            dir="rtl"
            labelDirection="rtl"
            InputProps={{
              readOnly: true,
            }}
          />

          <TextField
            itemID="educationSubTitle"
            label="عنوان فرعی آموزش"
            value={educationData.subTitle}
            onChange={(e) =>
              setEducationData({ ...educationData, subTitle: e.target.value })
            }
            fullWidth
            style={{ marginTop: "10px" }}
            dir="rtl"
            labelDirection="rtl"
            InputProps={{
              readOnly: true,
            }}
          />

          <TextField
            itemID="educationSubContent"
            label="محتوای فرعی آموزش"
            value={educationData.subContent}
            onChange={(e) =>
              setEducationData({ ...educationData, subContent: e.target.value })
            }
            fullWidth
            style={{ marginTop: "10px" }}
            dir="rtl"
            labelDirection="rtl"
            InputProps={{
              readOnly: true,
            }}
          />

          <TextField
            itemID="educationDate"
            label="تاریخ آموزش"
            value={educationData.educationDate}
            onChange={(e) =>
              setEducationData({
                ...educationData,
                educationDate: e.target.value,
              })
            }
            fullWidth
            style={{ marginTop: "10px", display: "none" }}
            dir="rtl"
            labelDirection="rtl"
            InputProps={{
              readOnly: true,
            }}
          />

          <TextField
            itemID="educationCategoryID"
            label="شناسه دسته‌بندی آموزش"
            value={educationData.educationCategoryID}
            onChange={(e) =>
              setEducationData({
                ...educationData,
                educationCategoryID: e.target.value,
              })
            }
            fullWidth
            style={{ marginTop: "10px", display: "none" }}
            dir="rtl"
            labelDirection="rtl"
            InputProps={{
              readOnly: true,
            }}
          />

          <div>
            <h2> فایل های پیوست شده :</h2>
            <DisplayImages
              filesData={educationFilenewList}
              // onDeleteFile={handleDeleteFile}
            ></DisplayImages>
          </div>
          <h2>لیست انتظار آپلود : </h2>
          <ul>
            {selectedFiles.map((file, index) => (
              <li key={index}>{file.name}</li>
            ))}
          </ul>

          <FileUploader3 onChange={handleFileChange} />
          {uploading && (
            <div style={{ color: "red", marginTop: "10px" }}>
              در حال آپلود فایل... لطفاً تا اتمام آپلود صفحه را نبندید.
              <p style={{ fontWeight: "bold" }}>
                درصد آپلود شده: {uploadProgress}
              </p>
            </div>
          )}
          <Button
            variant="contained"
            onClick={Idd > 0 ? insertFiles : insertFiles}
            sx={{ marginTop: 2 }}
            style={{ marginBottom: "1rem" }}
          >
            {Idd > 0 ? "افزودن فایل ها" : "افزودن فایل ها"}
          </Button>
        </div>
      </Paper>
    </Modal>
  );
}

export default ModalAttachEduFile;
