import React, { useEffect, useState, useContext } from "react";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { SelectedGroup } from "../../Contexts/SelectedGroup";
import MyTreeForList from "./MyTreeForList";
import axios from "axios";
import config from "../../config.js";
import EditIcon from "@mui/icons-material/Edit";

import DeleteIcon from "@mui/icons-material/Delete";
import { green, lightGreen, red, lightBlue } from "@mui/material/colors";
//import { ExerSelectedGroup } from '../../Contexts/excerGroupContext';
//import { ExerSelectedGroup } from '../../Contexts/excerGroupContext';
const ListView = (idd) => {
  const [items, setItems] = useState([]);
  const [itemsID, setItemsID] = useState([]);
  const [newItemText, setNewItemText] = useState("");
  const { selectedGroupsList } = useContext(SelectedGroup);
  const { selectedtgrp, updateExerSelectedGroupVariable } =
    useContext(SelectedGroup);
  const [treeKey, setTreeKey] = useState(0); // افزودن یک state برای key
  // const [ exerSelected1, setexerSelected] = useContext(ExerSelectedGroup);
  //const { packageID } = useContext(PackageID)
  // const { drt} = useContext(ExerSelectedGroup);

  const posteListeGroupe = (newItemsID) => {
    // const localStorageData = localStorage.getItem('user');

    // console.log("rrrr2",itemsID);
    const newCategorise = {
      exerciseID: idd,
      ctgIDList: newItemsID,
    };
    console.log("newCategorise:", newCategorise);
    try {
      let jwt_token = "";
      const localStorageData = localStorage.getItem("user");
      if (localStorageData) {
        const parsedData = JSON.parse(localStorageData);
        jwt_token = parsedData.token;
      } else {
        jwt_token = "no";
      }

      const authToken = jwt_token;
      console.log("eeddd");
      const headers = {
        Authorization: `Bearer ${authToken}`,
      };

      const response = axios.put(
        config.hostname + `/api/v1/Exercise/SetCategories`,
        {
          exerciseID: newCategorise.exerciseID.idd,
          ctgIDList: newCategorise.ctgIDList,
        },
        { headers }
      );

      console.log("ویرایش با موفقیت انجام شد", response.data);

      //onClose();
    } catch (error) {
      console.error("خطا در ویرایش کاربر: ", error);
    }
  };

  const addItem = () => {
    const inputElement = document.querySelector('input[itemID="maingrpid"]');
    const inputElement2 = document.querySelector('input[itemID="editgroupid"]');
    const inputElement3_btnokid = document.querySelector(
      'button[itemID="btnokid"] span'
    );
    if (inputElement2.style.display == "inline") {
      SaveGroupName(inputElement2.value);
      updateExerSelectedGroupVariable({
        keyid: selectedtgrp.keyID,
        text: inputElement2.value,
      });
      inputElement.style.display = "inline";
      inputElement2.style.display = "none"; // or 'block', depending on your use case
      inputElement2.value = "";
      inputElement3_btnokid.textContent = "افزودن به لیست";
    } else {
      console.log("rrr1", itemsID);
      if (selectedtgrp.keyid !== 0) {
        if (!itemsID.includes(selectedtgrp.keyid)) {
          console.log("000000000000");

          setItems((prevItems) => {
            const newItems = [...prevItems, selectedtgrp.text];
            //posteListeGroupe(newItems, itemsID);
            return newItems;
          });

          setItemsID((prevItemsID) => {
            const newItemsID = [...prevItemsID, selectedtgrp.keyid];
            posteListeGroupe(newItemsID);
            return newItemsID;
          });

          updateExerSelectedGroupVariable({
            keyid: 0,
            text: "",
          });
          setNewItemText("");
        }
      }
      //else
    }
  };

  /*
  const addItem = () => {
    
    console.log("rrr1",itemsID);
    if (selectedtgrp.keyid != 0)
    {
      if (!itemsID.includes(selectedtgrp.keyid)) {
        console.log('000000000000');
     
        setItems([...items,selectedtgrp.text]);
        setItemsID([...itemsID,selectedtgrp.keyid]);
        console.log(items,selectedtgrp.text);
        console.log(itemsID,selectedtgrp.keyid);
        updateExerSelectedGroupVariable({
          keyid:0,
          text:''
        });
        setNewItemText('');

      posteListeGroupe();
    }}

  };*/

  const removeItem = (index) => {
    const newItems = [...items];
    newItems.splice(index, 1);
    setItems(newItems);

    // const newItemsID = [...itemsID];
    //  newItemsID.splice(index, 1);
    //  setItemsID(newItemsID);

    setItemsID((prevItemsID) => {
      const newItemsID = [...prevItemsID];
      newItemsID.splice(index, 1);

      // const newItemsID = [...prevItemsID, selectedtgrp.keyid];
      posteListeGroupe(newItemsID);
      return newItemsID;
    });

    posteListeGroupe(itemsID);
  };

  const refreshlistgroup = () => {
    ///api/v1/Exercise/103
    console.log("m2", idd);
    const newexer = {
      exerciseID: idd,
    };
    // console.log("m2",newexer.exerciseID.idd);
    let jwt_token = "";
    const localStorageData = localStorage.getItem("user");
    if (localStorageData) {
      const parsedData = JSON.parse(localStorageData);
      jwt_token = parsedData.token;
    } else {
      jwt_token = "no";
    }
    const authToken = jwt_token;
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    axios
      ///api/v1/ExerciseCtg/GetCategoriesByExerID?ExerciseID=103
      //  .get(config.hostname+`/api/v1/Exercise/${Idd}`, { headers })
      .get(
        config.hostname +
          `/api/v1/ExerciseCtg/GetCategoriesByExerID?ExerciseID=${newexer.exerciseID.idd}`,
        { headers }
      )
      .then((response) => {
        const exercisesctgDataaa = response.data;
        console.log(exercisesctgDataaa);

        const keyTEXTArray = exercisesctgDataaa.map((item) => item.ctgName);
        const keyIDsArray = exercisesctgDataaa.map((item) => item.keyID);

        // console.log("itemsliade1", keyIDsArray);
        // ذخیره آرایه keyID ها در itemsID
        setItemsID(keyIDsArray);
        setItems(keyTEXTArray);

        //  console.log("itemsliade", itemsID);

        /* setItemsID({
          name: exercisesDataaa.name,
          subject1: exercisesDataaa.subject1,
          subject2: exercisesDataaa.subject2,
          preparation: exercisesDataaa.preparation,
          execution: exercisesDataaa.execution,

        }); */
      })
      .catch((error) => {
        console.error("خطا در درخواست به API: ", error);
      });
  };
  useEffect(() => {
    // console.log("itemsliade", itemsID);
  }, [itemsID]); // اجرا شود هرگاه itemsID تغییر کند

  useEffect(() => {
    // console.log("key chera");
    // console.log('selectedGroupsList', selectedGroupsList);
    // هر بار که selectedGroupsList تغییر کند، key را تغییر دهید
    setTreeKey((prevKey) => prevKey + 1);
    refreshlistgroup();
  }, [selectedGroupsList]);
  const SaveGroupName = (newgrpname) => {
    try {
      let jwt_token = "";
      const localStorageData = localStorage.getItem("user");
      if (localStorageData) {
        const parsedData = JSON.parse(localStorageData);
        jwt_token = parsedData.token;
      } else {
        jwt_token = "no";
      }

      const authToken = jwt_token;
      const headers = {
        Authorization: `Bearer ${authToken}`,
      };

      axios
        .put(
          config.hostname + `/api/v1/ExerciseCtg`,
          {
            keyID: selectedtgrp.keyid,
            parentKeyID: -1,
            categoryName: newgrpname,
          },
          { headers }
        )
        .then((response) => {
          console.log("ویرایش با موفقیت انجام شد", response.data);
          // handleRefresh(); // Execute handleRefresh after successful PUT
        })
        .catch((error) => {
          console.error("خطا در ویرایش : ", error);
        });
    } catch (error) {
      console.error("خطا در ویرایش : ", error);
    }
    //  setIsEdited(false);
  };

  const handleedtigroup = () => {
    const inputElement = document.querySelector('input[itemID="maingrpid"]');
    const inputElement2 = document.querySelector('input[itemID="editgroupid"]');
    const inputElement3_btnokid = document.querySelector(
      'button[itemID="btnokid"] span'
    );

    if (inputElement2.style.display == "inline") {
      //وقتی رنگ زرد و در حالت ادیت هست
      inputElement.style.display = "inline";
      inputElement2.style.display = "none"; // or 'block', depending on your use case
      inputElement2.value = "";
      inputElement3_btnokid.textContent = "افزودن به لیست";
      // inputElement2.style.backgroundColor = 'yellow';   // Set the color to yellow
      //  console.log("1");
    } else {
      if (selectedtgrp.keyid > 0) {
        inputElement.style.display = "none";
        inputElement2.style.display = "inline";
        inputElement2.value = inputElement.value;
        inputElement3_btnokid.textContent = "ویرایش نام گروه";
      } else {
        if (
          window.confirm(
            "لطفا ابتدا از منوی درختی یک گروه را انتخاب نمایید سپس برای ویرایش نام گروه اقدام کنید"
          )
        ) {
          // Code to execute if the user clicks "OK"
          console.log("User clicked OK. Performing further actions...");
        } else {
          // Code to execute if the user clicks "Cancel" or closes the dialog
          console.log("User clicked Cancel or closed the dialog.");
        }

        // Code to execute if the user clicks "OK" in the confirmation dialog
      }
    }
  };

  const deletegroup = () => {
    //https://api.bettermove.ir/api/v1/ExerciseCtg/1
    let jwt_token = "";
    const localStorageData = localStorage.getItem("user");
    if (localStorageData) {
      const parsedData = JSON.parse(localStorageData);
      jwt_token = parsedData.token;
    } else {
      jwt_token = "no";
    }
    const authToken = jwt_token;
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    axios
      .delete(config.hostname + `/api/v1/ExerciseCtg/${selectedtgrp.keyid}`, {
        headers,
      })

      .catch((error) => {
        console.error("خطا در درخواست به API برای حذف گروه: ", error);
      });
  };

  const handleDeletegroup = () => {
    if (selectedtgrp.keyid <= 0) {
      if (
        window.confirm(
          "لطفا ابتدا از منوی درختی یک گروه را انتخاب نمایید سپس برای حذف گروه اقدام کنید"
        )
      ) {
        // Code to execute if the user clicks "OK"
        console.log("User clicked OK. Performing further actions...");
      } else {
        // Code to execute if the user clicks "Cancel" or closes the dialog
        console.log("User clicked Cancel or closed the dialog.");
      }
    } else {
      if (window.confirm("شما در حال حذف یک گروه هستید. آیا ادامه می دهید؟")) {
        // Code to execute if the user clicks "OK"
        //delete
        deletegroup();
      } else {
      }
    }
  };
  const handleAddgroup = (subgroupid) => {
    //https://api.bettermove.ir/api/v1/ExerciseCtg
    const inputElement = document.querySelector('input[itemID="newgroupid"]');
    if (inputElement.value.trim() != "") {
      const ginfo = {
        keyID: -1,
        parentKeyID: subgroupid === -1 ? -1 : subgroupid,
        CategoryName: inputElement.value,
      };
      //console.log(ginfo);
      const localStorageData = localStorage.getItem("user");
      if (localStorageData) {
        const parsedData = JSON.parse(localStorageData);
        const token = parsedData.token;
        fetch(`https://api.bettermove.ir/api/v1/ExerciseCtg`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(ginfo),
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            if (response.status === 200) {
              inputElement.value = "";
              return "OK";
            }
            return response.json();
          })
          .then((data) => {
            if (data === "OK") {
              console.log("Data successfully sent.");
            } else {
              console.log("Data successfully sent:", data);
            }
          })
          .catch((error) => {
            console.error("Error sending data:", error);
          });
      }
    }
  };

  return (
    <div>
      <br></br>
      <input type="text" itemID="newgroupid" />
      <button onClick={() => handleAddgroup(-1)}>
        +افزودن گروه در شاخه اصلی
      </button>
      <button onClick={() => handleAddgroup(selectedtgrp.keyid)}>
        +افزودن زیر گروه
      </button>
      <br></br>
      <br></br>
      <label>گروه انتخاب شده:</label>
      <input type="text" value={selectedtgrp.text} itemID={"maingrpid"} />
      <input
        type="text"
        itemID="editgroupid"
        placeholder="نام جدید گروه وارد شود"
        style={{ display: "none", backgroundColor: "yellow" }}
      />
      <button onClick={addItem} itemID={"btnokid"}>
        <span>افزودن به لیست</span>
        <AddIcon style={{ backgroundColor: lightBlue, fontSize: "10px" }} />
      </button>
      <button onClick={handleedtigroup}>
        <EditIcon style={{ backgroundColor: lightGreen, fontSize: "10px" }} />
      </button>
      <button onClick={handleDeletegroup}>
        <DeleteIcon style={{ backgroundColor: red, fontSize: "10px" }} />
      </button>
      <ul>
        {items.map((item, index) => (
          <li key={index}>
            {item}
            <button onClick={() => removeItem(index)}>
              <RemoveIcon style={{ fontSize: "10px" }} />
            </button>
          </li>
        ))}
      </ul>
      {/* افزودن key به MyTree 


      <MyTreeForList key={treeKey} fetchedData={selectedGroupsList} />
*/}
    </div>
  );
};

export default ListView;
