import React, { useState } from "react";
import { products } from "./../../datas";
import { DataGrid, useGridSelector } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import "./Products.css";

function UsersList() {
  const [productsDatas, setProductsDatas] = useState(products);

  const productDelete = (productId) => {
    setProductsDatas(
      productsDatas.filter((product) => product.id != productId)
    );
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
    },
    {
      field: "Title",
      headerName: "Title",
      width: 200,
      renderCell: (params) => {
        return (
          <>
            <Link to={`/product/${params.row.id}`} className="link">
              <div className="productListProduct">
                <img src={params.row.avatar} className="productListImg" />
                {params.row.title}
              </div>
            </Link>
          </>
        );
      },
    },
    {
      field: "price",
      headerName: "Price",
      width: 120,
    },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <Link to={`/product/${params.row.id}`} className="link">
              <EditIcon className="productListEdit" />
            </Link>
            <DeleteIcon
              className="productListTrash"
              onClick={() => productDelete(params.row.id)}
            />
          </>
        );
      },
    },
  ];

  return (
    <>
      {/* <div sx={{ height: 520, width: '90%' }} className='productList'>
            <DataGrid
                rows={productsDatas}
                columns={columns}
                pageSize={3}
                disableSelectionOnClick
                checkboxSelection
            />
        </div> */}
    </>
  );
}

export default UsersList;
