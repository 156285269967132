import React, { useState, useEffect, useContext } from "react";
import { Modal, Paper, TextField, Button } from "@mui/material";
import MyTree from "./treeview";
import TextFieldwithAddIcon from "../../Components/TextFildwithAddIcon/TextFildwithAddIcon";
import { red } from "@mui/material/colors";
import axios from "axios";
import config from "../../config.js";
import ListView from "./listview";
import { SelectedGroup } from "../../Contexts/SelectedGroup";

function ModalGroup({ isOpen, onClose, Idd }) {
  const { selectedGroupsList } = useContext(SelectedGroup);

  const [exercisesData, setExercisesData] = useState({
    name: "",
    subject1: "",
    subject2: "",
    preparation: "",
    execution: "0",
  });

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [paremeterLink, setparemeterLink] = useState("");
  const [parameters, setParameters] = useState(null);
  const [fetchedtdataset, setfetchedtdataset] = useState([]);

  function mapdatatofetcheddata(rawData) {
    const fetchedData = [];

    const groupByKeyID = {};

    // گروه‌بندی بر اساس keyID
    rawData.forEach((item) => {
      if (item.fK_ParentID === null) {
        groupByKeyID[item.keyID] = {
          keyID: item.keyID,
          name: item.ctgName,
          children: [],
        };
      } else {
        const parent = groupByKeyID[item.fK_ParentID];
        if (parent) {
          parent.children.push({
            keyID: item.keyID,
            name: item.ctgName,
          });
        }
      }
    });

    // افزودن داده‌های گروه‌بندی شده به آرایه fetchedData
    Object.values(groupByKeyID).forEach((group) => {
      fetchedData.push(group);
    });
    setfetchedtdataset(fetchedData);
    console.log(" tereeee", fetchedData);
  }

  useEffect(() => {
    // Your useEffect logic here
    if (Idd) {
      let jwt_token = "";
      const localStorageData = localStorage.getItem("user");
      if (localStorageData) {
        const parsedData = JSON.parse(localStorageData);
        jwt_token = parsedData.token;
      } else {
        jwt_token = "no";
      }
      const authToken = jwt_token;
      const headers = {
        Authorization: `Bearer ${authToken}`,
      };

      axios
        .get(config.hostname + `/api/v1/Exercise/${Idd}`, { headers })
        .then((response) => {
          const exercisesctgDataaa = response.data;
          console.log("exerrr", exercisesctgDataaa.name);
          setExercisesData({
            name: exercisesctgDataaa.name,
          });
        })
        .catch((error) => {
          console.error("خطا در درخواست1 به API: ", error);
        });

      axios
        .get(config.hostname + "/api/v1/ExerciseCtg", { headers })
        .then((response) => {
          const ctgData = response.data;

          mapdatatofetcheddata(ctgData);
        })
        .catch((error) => {
          console.error("خطا در درخواست به API: ", error);
        });
    }
  }, [Idd]);

  const handleFileChange = (files) => {
    setSelectedFiles(files);
  };

  const updateUser = async () => {
    // Your updateUser logic here
  };

  const insertuser = async () => {
    // Your insertuser logic here
  };

  const posteListeGroupe = (id) => {
    // const localStorageData = localStorage.getItem('user');

    const newCategorise = {
      exerciseID: Idd,
      ctgIDList: selectedGroupsList.map((item) => item.keyID),
    };
    // console.log('newCategorise:', newCategorise)
    try {
      let jwt_token = "";
      const localStorageData = localStorage.getItem("user");
      if (localStorageData) {
        const parsedData = JSON.parse(localStorageData);
        jwt_token = parsedData.token;
      } else {
        jwt_token = "no";
      }

      const authToken = jwt_token;
      console.log("eeddd");
      const headers = {
        Authorization: `Bearer ${authToken}`,
      };

      const response = axios.put(
        config.hostname + `/api/v1/Exercise/SetCategories`,
        {
          exerciseID: Idd,
          ctgIDList: selectedGroupsList.map((item) => item.keyID),
        },
        { headers }
      );

      console.log("ویرایش با موفقیت انجام شد", response.data);

      onClose();
    } catch (error) {
      console.error("خطا در ویرایش کاربر: ", error);
    }
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Paper
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 800,
          height: 500,
          padding: 2,
        }}
      >
        <div
          style={{
            width: "300px",
            height: "100%",
            marginLeft: "10px",
            backgroundColor: "white",
          }}
        >
          <div> گروه ها</div>
          <MyTree fetchedData={fetchedtdataset} />
        </div>
        <div
          style={{
            flex: "1",
            padding: "0 10px",
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <h2>{Idd > 0 ? "دسته بندی تمرین" : "دسته بندی تمرین"}</h2>
          <hr style={{ borderTop: "2px solid blue", margin: "10px 0" }} />
          {/* Your main content here */}
          {/* ... */}
          <TextField
            itemID="exercisesName"
            label="عنوان تمرین"
            value={exercisesData.name}
            fullWidth
            style={{ marginTop: "10px" }}
            dir="rtl"
            InputProps={{
              readOnly: true,
            }}
          />

          {/* ... (other form fields) */}
          <ListView idd={Idd} />

          {/* <Button
          variant="contained"
          onClick={Idd > 0 ? insertuser : insertuser}
          sx={{ marginTop: 2 }}
        > */}
          {/*   <Button
        variant="contained"
        onClick={posteListeGroupe}
        sx={{ marginTop: 2 }}
      >
          Idd > 0 ? "ذخیره تگ ها" : "ذخیره تگ ها"
        </Button>*/}
        </div>
      </Paper>
    </Modal>
  );
}

export default ModalGroup;
