function validatePassword(password, minlength) {
  if (!password) return "رمزعبور خود را وارد کنید";

  if (password.length < minlength) {
    return `لطفا یک رمز عبور با حداقل ${minlength} حرف وارد کنید`;
  }

  const hasCapitalLetter = /[A-Z]/g;
  if (!hasCapitalLetter.test(password)) {
    return "لطفا حداقل از یک حرف بزرگ استفاده کنید";
  }

  const hasNumber = /\d/g;
  if (!hasNumber.test(password)) {
    return "لطفا حداقل از یک عدد استفاده کنید";
  }

  return "";
}

function validatePasswordRepeat(passwordRepeat, password) {
  if (!passwordRepeat) return "تکرار رمز عبور خود را وارد کنید";
  if (passwordRepeat !== password)
    return "تکرار رمزعبور با رمزعبور تطابق ندارد";
  return "";
}

function validateEmail(email) {
  if (!email) return "ایمیل خود را وارد کنید";

  const isValidEmail = /^\S+@\S+$/g;
  if (!isValidEmail.test(email)) {
    return "لطفا یک ایمیل معتبر وارد کنید";
  }

  return "";
}

function validateName(name, fieldName, minlength) {
  if (!name) return `لطفا ${fieldName} خود را وارد کنید`;

  if (name.length < minlength)
    return `${fieldName} نمیتواند کمتر از ${minlength} حرف داشته باشد`;

  const hasNumber = /\d/;
  if (hasNumber.test(name)) return `${fieldName} نمیتواند عدد داشته باشد`;
  return "";
}

function validateUsername(username) {
  if (!username) return `لطفا نام کاربری خود را وارد کنید`;

  const isValidUsername = /^[a-z0-9_-]{3,15}$/;
  if (!isValidUsername.test(username))
    return `لطفا یک نام کاربری معتبر وارد کنید`;

  return "";
}

function validatePhone(phone) {
  if (!phone) return "لطفا شماره تلفن خود را وارد کنید";

  const isValidatePhone = /^(0|0098|\+98)9(0[1-5]|[1 3]\d|2[0-2]|98)\d{7}$/;
  if (!isValidatePhone.test(phone)) return "شماره تلفن معتبر نیست";

  return "";
}

export {
  validatePassword,
  validatePasswordRepeat,
  validateEmail,
  validateName,
  validateUsername,
  validatePhone,
};
