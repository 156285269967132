import React, { useState, useEffect } from "react";

import { Modal, Paper, TextField, Button } from "@mui/material";
import axios from "axios";
import config from "../../config";
import FileUploader2 from "../../Components/files/FileUploader2";
import PersianDatePicker from "../../Components/PersianDatePicker";
import jalaliMoment from "jalali-moment";
import FileUploader3 from "../../Components/files/FileUploader3";
import DisplayImages from "./DisplayImage";

function ModalUser({ isOpen, onClose, Idd }) {
  const [selectedMiladiDate, setSelectedMiladiDate] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const handleDateChange = (miladiDate) => {
    setSelectedMiladiDate(miladiDate);
  };

  const [selectedGregorianDate, setSelectedGregorianDate] = useState(null);

  const handleGregorianDateChange = (gregorianDate) => {
    setSelectedGregorianDate(gregorianDate);
  };

  const initialValues = {
    title: "",
    content: "",
    subTitle: "",
    subContent: "",
    educationDate: "",
    educationCategoryID: 0,
  };

  const [educationData, setEducationData] = useState(initialValues);
  

  const convertGregorianToShamsi = (gregorianDate) => {
    return jalaliMoment(gregorianDate).format("jYYYY-jMM-jDD");
  };

  useEffect(() => {
    if (Idd > 0) {
      let jwt_token = "";
      const localStorageData = localStorage.getItem("user");
      if (localStorageData) {
        const parsedData = JSON.parse(localStorageData);
        jwt_token = parsedData.token;
      } else {
        jwt_token = "no";
      }

      const authToken = jwt_token;
      const headers = {
        Authorization: `Bearer ${authToken}`,
      };
      axios
        .get(config.hostname + `/api/v1/Education/${Idd}`, { headers })
        .then((response) => {
          const educationDataaa = response.data;
          setEducationData({
            title: educationDataaa.title,
            content: educationDataaa.content,
            subTitle: educationDataaa.subTitle,
            subContent: educationDataaa.subContent,
            educationDate: educationDataaa.educationDate,
            educationCategoryID: educationDataaa.educationCategoryID,
          });
        })
        .catch((error) => {
          console.error("خطا در درخواست به API: ", error);
        });

      const initialGregorianDate = "2023-01-01";
    } else {
      setEducationData(initialValues);
    }
  }, [Idd]);

  const updateEducation = async () => {
    try {
      let jwt_token = "";
      const localStorageData = localStorage.getItem("user");
      if (localStorageData) {
        const parsedData = JSON.parse(localStorageData);
        jwt_token = parsedData.token;
      } else {
        jwt_token = "no";
      }

      const authToken = jwt_token;
      const headers = {
        Authorization: `Bearer ${authToken}`,
      };

      const response = await axios.put(
        config.hostname + `/api/v1/Education`,
        {
          keyID: Idd,
          title: educationData.title,
          content: educationData.content,
          subTitle: educationData.subTitle,
          subContent: educationData.subContent,
          educationDate: educationData.educationDate,
          educationCategoryID: educationData.educationCategoryID,
        },
        { headers }
      );

      console.log("ویرایش با موفقیت انجام شد", response.data);
      setEducationData(initialValues);
      onClose();
    } catch (error) {
      console.error("خطا در ویرایش کاربر: ", error);
    }
  };

  const insertEducation = async () => {
    try {
      let jwt_token = "";
      const localStorageData = localStorage.getItem("user");
      if (localStorageData) {
        const parsedData = JSON.parse(localStorageData);
        jwt_token = parsedData.token;
      } else {
        jwt_token = "no";
      }
      const authToken = jwt_token;
      const headers = { Authorization: `Bearer ${authToken}` };

      const response = await axios.post(
        config.hostname + `/api/v1/Education`,
        {
          keyID: -1,
          title: educationData.title,
          content: educationData.content,
          subTitle: educationData.subTitle,
          subContent: educationData.subContent,
          educationDate: new Date().toISOString(),
          educationCategoryID: 0,
        },
        { headers }
      );

      console.log("ایجاد با موفقیت انجام شد", response.data);
      setEducationData(initialValues);
      onClose();
    } catch (error) {
      console.error("خطا در ایجاد : ", error);
    }
  };

  return (
    <Modal className="add-education-modal" open={isOpen} onClose={onClose}>
      <Paper
        sx={{
          position: "absolute",
          display: "flex",
          flexDirection: "row",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
          padding: 2,
        }}
      >
        <div style={{ flex: 1, padding: "0 10px" }}>
          <h2>{Idd > 0 ? "ویرایش آموزش" : "ایجاد آموزش"}</h2>
          <hr style={{ borderTop: "2px solid blue", margin: "10px 0" }} />

          <div>
            <TextField
              itemID="educationTitle"
              label="عنوان آموزش"
              value={educationData.title}
              onChange={(e) =>
                setEducationData({ ...educationData, title: e.target.value })
              }
              fullWidth
              style={{ marginTop: "10px" }}
              dir="rtl"
            />

            <TextField
              itemID="educationContent"
              label="محتوای آموزش"
              value={educationData.content}
              onChange={(e) =>
                setEducationData({ ...educationData, content: e.target.value })
              }
              multiline
              rows={6}
              fullWidth
              style={{ marginTop: "10px" }}
              dir="rtl"
            />

            <TextField
              itemID="educationSubTitle"
              label="عنوان فرعی آموزش"
              value={educationData.subTitle}
              onChange={(e) =>
                setEducationData({ ...educationData, subTitle: e.target.value })
              }
              fullWidth
              style={{ marginTop: "10px" }}
              dir="rtl"
            />

            <TextField
              itemID="educationSubContent"
              label="سایر توضیحات آموزش"
              value={educationData.subContent}
              onChange={(e) =>
                setEducationData({
                  ...educationData,
                  subContent: e.target.value,
                })
              }
              multiline
              rows={6}
              fullWidth
              style={{ marginTop: "10px" }}
              dir="rtl"
            />

            <TextField
              itemID="educationDate"
              label="تاریخ آموزش"
              value={educationData.educationDate}
              onChange={(e) =>
                setEducationData({
                  ...educationData,
                  educationDate: e.target.value,
                })
              }
              fullWidth
              style={{ marginTop: "10px", display: "none" }}
              dir="rtl"
            />

            <TextField
              itemID="educationCategoryID"
              label="شناسه دسته‌بندی آموزش"
              value={educationData.educationCategoryID}
              onChange={(e) =>
                setEducationData({
                  ...educationData,
                  educationCategoryID: e.target.value,
                })
              }
              fullWidth
              style={{ marginTop: "10px", display: "none" }}
              dir="rtl"
            />
          </div>

          <Button
            variant="contained"
            onClick={Idd > 0 ? updateEducation : insertEducation}
            style={{ marginBottom: "1rem" }}
            sx={{ marginTop: 2 }}
          >
            {Idd > 0 ? "ویرایش آموزش" : "ایجاد آموزش"}
          </Button>
        </div>
      </Paper>
    </Modal>
  );
}

export default ModalUser;
