import React, { useState, useEffect, useCallback } from "react";
import "./fonts.css";
import "./App.css";
import routes from "./routes";
import { useRoutes } from "react-router-dom";
import Topbar from "./Components/topbar/Topbar";
import Sidebar from "./Components/sidebar/Sidebar";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import AuthContext from "./Contexts/authContext";
import { SelectedGroupProvider } from "./Contexts/SelectedGroup";
import config from "./config";
import axios from "axios";
import { ErrorCode } from "react-dropzone";


function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [token, setToken] = useState(false);
  const [userInfose, setUserInfose] = useState({});
  const [localStorageToken, setLocalStorageToken] = useState("");
  const location = useLocation();
  const navigate = useNavigate();

  const login = useCallback((userInfose, token) => {
    setToken(token);
    setIsLoggedIn(true);
    setUserInfose(userInfose);
    localStorage.setItem("user", JSON.stringify({ token }));
  });

  const logout = useCallback(() => {
    setToken(null);
    setUserInfose({});
    localStorage.removeItem("user");
  });

  useEffect(() => {
    const localStorageData = JSON.stringify(localStorage.getItem("user"));
    setLocalStorageToken(localStorageData);
    if (!localStorageData) {
      fetch(``, {
        headers: {
          Authorization: `Bearer ${localStorageData.token}`,
        },
      })
        .then((res) => res.json())
        .then((userdata) => {
          setIsLoggedIn(true);
          setUserInfose(userdata);
        });
    }
  }, [login]);

  const router = useRoutes(routes);
  //new
  // مثال تابع بررسی
  function isDataValid() {
    //return false;

    // چک کردن شرایط دلخواه
    let jwt_token = "";
    const localStorageData = localStorage.getItem("user");
    if (localStorageData) {
      const parsedData = JSON.parse(localStorageData);
      jwt_token = parsedData.token;
    } else {
      jwt_token = "no";
    }

    const authToken = jwt_token;
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    axios
      .get(config.hostname + `/api/v1/Education/1`, { headers })
      /*.then((response) => {
      if(response.status=401){
        console.log("401");
      }
     })*/
      .catch((error) => {
        //console.error('خطا در درخواست به API: ', error);
        if (error.response && error.response.status === 401) {
          console.log("خطای 401: احراز هویت ناموفق");
          // اینجا می‌توانید هر عمل مورد نظر خود را انجام دهید
          // مثلاً return false;
          logout();
          return false;
        } else {
          // خطاهای دیگر
          console.error("خطا در درخواست به API: ", error);
          return true;
        }
      });
  }
  //

  useEffect(() => {
    const localStorageData = JSON.parse(localStorage.getItem("user"));
    if (localStorageData) {
      let jwt_token = "";
      const localStorageData1 = localStorage.getItem("user");
      if (localStorageData1) {
        const parsedData = JSON.parse(localStorageData1);
        jwt_token = parsedData.token;
      } else {
        jwt_token = "no";
      }

      const authToken = jwt_token;
      const headers = {
        Authorization: `Bearer ${authToken}`,
      };
      axios
        .get(config.hostname + `/api/v1/Education/1`, { headers })
        /*.then((response) => {
        if(response.status=401){
          console.log("401");
        }
       })*/
        .catch((error) => {
          //console.error('خطا در درخواست به API: ', error);
          if (error.response && error.response.status === 401) {
            console.log("خطای 401: احراز هویت ناموفق");

            logout();
          } else {
            // خطاهای دیگر
            console.error("خطا در درخواست به API: ", error);
          }
        });
      setIsLoggedIn(true);
      setToken(localStorageData.token);
      console.log("login success");
    } else {
      console.log("login test2");
      // اگر توکن موجود نبود و کاربر وارد صفحه لاگین نشده بود، الرت نمایش داده شود.
      if (location.pathname !== "/login") {
        Swal.fire({
          icon: "warning",
          title: "ورود به سیستم",
          text: "قبل از ورود به سیستم ابتدا باید لاگین کنید.",
        }).then(
          (result) => {
            // if (result.isConfirmed) {
            navigate("/login");
          }
          // else {
          //   Swal.fire('تلاش دوباره', 'درخواست تکراری', 'error');
          // }
        );
      }
    }
  }, [location.pathname]);

  return (
    <>
      <Topbar />
      <div className="container">
        <SelectedGroupProvider>
          <AuthContext.Provider
            value={{
              isLoggedIn,
              token,
              userInfose,
              login,
              logout,
            }}
          >
            {/* {!isLoggedIn && <Navigate to="/login" />} */}
            <Sidebar />
            {router}

            {/*  <Sidebar />*/}
          </AuthContext.Provider>
        </SelectedGroupProvider>
      </div>
    </>
  );
}

export default App;
